<template>
  <v-fade-transition>
    <v-bottom-sheet
      inset
      v-model="status"
    >
      <v-list>
        <v-subheader>{{'photo.action.select_size'| t}}</v-subheader>

        <v-list-item
            v-for="(sizeListItem, suffix) in sizeList"
            :key="suffix"
        >
          <v-list-item-content>
            <div class="d-flex">
              <div class="subtitle-1 mr-4">
                {{suffix}} ({{sizeListItem.width}} x {{sizeListItem.height}})
              </div>

              <v-btn small color="primary" @click="choice(sizeListItem)">
                {{'action.apply'| t}}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </v-fade-transition>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    photo: {
      type: Object,
      default: () => null,
    },
    choice: {
      type: Function,
    },
  },
  data: () => ({
    status: false,
  }),
  computed: {
    sizeList() {
      if(!this.photo) return null
      return this.photo.size_list
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.status = this.value
      },
    },
    status: {
      handler() {
        this.$emit('input', this.status)
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
